

























































































import { Vue, Component } from 'vue-property-decorator'
import { apiRecordInfo, apiRecordEdit } from '@/api/setting/shop'
import materialSelect from '@/components/material-select/index.vue'

@Component({
    components: {
        materialSelect
    }
})
export default class SettingRecord extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        copyright: '', // 版权信息
        record_number: '', // 备案号
        record_system_link: '', // 备案号链接
        business_license: '', // 营业执照
        other_qualifications: '' // 其它资质
    }

    // 表单验证
    // eslint-disable-next-line @typescript-eslint/ban-types
    rules: object = {
        record_system_link: [
            {
                pattern:
                    /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/,
                message: '请输入合法链接',
                trigger: 'blur'
            }
        ]
    }
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    initFormData() {
        apiRecordInfo()
            .then(res => {
                // 表单同步于接口响应数据
                for (const key in res) {
                    if (!this.form.hasOwnProperty(key)) {
                        continue
                    }
                    this.form[key] = res[key]
                }
            })
            .catch(() => {
                this.$message.error('数据加载失败，请刷新重载')
            })
    }

    // 重置表单数据
    onResetFrom() {
        this.initFormData()
        this.$message.info('已重置数据')
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement

        refs.validate((valid: boolean) => {
            if (!valid) {
                return
            }
            const loading = this.$loading({ text: '保存中' })
            const params = { ...this.form }

            apiRecordEdit({
                ...params
            })
                .then(() => {
                    this.$store.dispatch('getConfig')
                    this.$message.success('保存成功')
                })
                .catch(() => {
                    this.$message.error('保存失败')
                })
                .finally(() => {
                    loading.close()
                })
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.initFormData()
    }
    /** E Life Cycle **/
}
